<template>
  <div class="game-error-modal" data-t="game-geo-error-modal" :class="platform">
    <StDrawerHeader v-if="platform === 'mobile'" class="drawer-header" />
    <div class="image-wrapper">
      <img
        src="./brokenSlotMachine.png"
        alt="broken slot machine"
        class="image"
        width="162"
        height="162"
        data-t="slot-machine-image"
      />
    </div>
    <div class="content">
      <h2 class="title" data-t="title">{{ t('gameErrors.vpnErrorTitle') }}</h2>
      <h3 class="second-header" data-t="header">
        {{ t('restriction.howGetAccess') }}
      </h3>
      <I18nT class="info" data-t="info" keypath="restriction.vpn" tag="p">
        <template #countries>
          <b>
            <span class="countries" data-t="countries">
              {{ t('restriction.countries') }}
            </span>
          </b>
        </template>
      </I18nT>
      <div class="boxes">
        <a
          class="box"
          :href="createSupportLink('/articles/9516574')"
          target="_blank"
          data-t="help-box"
        >
          <StIcon
            class="box-icon"
            name="book-solid"
            size="20"
            data-t="box-icon"
          />
          <span class="box-title" data-t="box-title">
            {{ t('restriction.reference') }}
          </span>
        </a>
        <span
          id="intercomButton"
          class="box"
          data-t="live-chat-box"
          aria-role="button"
          @click="toogleChat"
        >
          <StIcon
            class="box-icon"
            name="support-solid"
            size="20"
            data-t="support-solid"
          />
          <span class="box-title" data-t="live-icon">
            {{ t('restriction.live') }}
          </span>
        </span>
        <a class="box" :href="supportEmailLink" data-t="email-box">
          <StIcon
            class="box-icon"
            name="mail-solid"
            size="20"
            data-t="mail-solid-icon"
          />
          <span class="box-title" data-t="restriction-email">
            {{ t('restriction.email') }}
          </span>
        </a>
      </div>
      <StButton
        type="ghost"
        :label="t('gameErrors.close')"
        size="l"
        block
        data-t="close-button"
        :to="{ query: { ...route.query, modal: undefined } }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCasinoDeps } from '../../useDeps'

const { t } = useI18n()

const route = useRoute()
const { useIntercomApi } = useCasinoDeps()
const { toogleChat } = useIntercomApi()

const { createSupportLink, supportEmailLink } = useSupportUrl()
const { platform } = usePlatform()
</script>

<style scoped>
.image {
  margin-top: var(--spacing-500);
  margin-bottom: var(--spacing-250);
}

.drawer-header {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
}

.content {
  padding: 0 var(--spacing-500) var(--spacing-500);
  text-align: center;
}

.title {
  margin: 0 0 var(--spacing-500);
  font: var(--desktop-text-xl-semibold);
}

.second-header {
  margin: 0 0 var(--spacing-050);
  font: var(--desktop-text-md-semibold);
}

.info {
  margin: 0 0 var(--spacing-150);
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
  text-align: center;

  b {
    color: var(--text-primary);
  }
}

.game-error-modal.desktop {
  width: 480px;
}

.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-icon {
  color: var(--icon-secondary);
}

.boxes {
  display: flex;
  gap: var(--spacing-100);
  justify-content: center;

  width: 100%;
  margin-bottom: var(--spacing-500);
}

.box {
  cursor: pointer;
  user-select: none;

  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  gap: var(--spacing-075);
  align-items: center;
  justify-content: center;

  padding: var(--spacing-150) var(--spacing-150) var(--spacing-125);

  color: var(--text-primary);
  text-decoration: none;

  border: var(--border-width-medium) solid var(--border-secondary);
  border-radius: var(--border-radius-100);

  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--background-secondary);
  }
}

.mobile {
  .title {
    margin-bottom: var(--spacing-300);
    font: var(--mobile-title-1-semibold);
  }

  .second-header {
    font: var(--mobile-headline-semibold);
  }

  .info {
    margin-bottom: var(--spacing-100);
    font: var(--mobile-text-content-regular);
  }

  .box-title {
    font: var(--mobile-text-content-regular);
  }

  .content {
    padding: 0 var(--spacing-200) var(--spacing-300);
  }

  .box {
    padding: var(--spacing-125) var(--spacing-100);
  }

  .boxes {
    margin-bottom: var(--spacing-300);
  }
}
</style>
